<template>
  <v-bottom-navigation id="Navbar_mysite" color="dark" grow>
    <v-btn v-for="item in items" :key="item.title" class="align-self-center" v-bind:to="item.path">
      <span>{{ item.title }}</span>
      <v-icon>{{ item.icon }}</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
export default {
  name: "Navbar",
  data: () => ({
    items: [
      {title: 'HOME', path: '/', icon: 'mdi-home'},
      {title: 'ABOUT', path: '/about', icon: 'mdi-forum'},
      {title: 'SKILL', path: '/skill', icon: 'mdi-code-greater-than'},
    ],
  }),
}
</script>

<style scoped>
#Navbar_mysite {
  background-color: lightskyblue;
}

.theme--light.v-btn.v-btn--has-bg {
  background-color: unset;
}
</style>
