<template>
  <v-container id="homes" fill-height fluid>
    <v-layout wrap justify-space-around>
      <v-flex md4 sm6 xs12 align-self-center>
        <h1 id="menu_home_title">New Video</h1>
        <div class="movie-wrap">
          <iframe allow="accelerometer; autoplay;
              clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
                  frameborder="0" title="YouTube video player"
                  height="315" src="https://www.youtube-nocookie.com/embed/videoseries?list=UUnxKzi_m-BqLc_JpxU2NcGg"
                  width="560">
          </iframe>
        </div>
      </v-flex>
      <v-flex md4 sm6 xs12 align-self-center>
        <div id="sns">
          <h1 id="menu_title_info">SNS</h1>
          <v-btn v-for="lists in sns_link" id="sns-btn" :key="lists.links" class="mx-2"
                 color="white" fab target="_blank" v-bind:href="lists.links">
            <v-icon id="sns-icon" v-bind:color="lists.color">{{ lists.icon }}</v-icon>
          </v-btn>
        </div>
        <div id="info">
          <v-alert color="primary" dark icon="mdi-information" prominent>
            商品提供・制作依頼(OP制作・ED制作等)は、下記のフォームまたはメールからお願い致します。<br>
            <span id="url_link">
                <a href="https://goo.gl/forms/eioVKPJ2ryzv7ytU2" target="_blank">依頼フォーム</a> /
                <a href="mailto:junch1225@gmail.com" target="_blank">メール</a>
              </span>
          </v-alert>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'Home',
  data: () => ({
    sns_link: [
      {icon: 'mdi-twitter', color: '#1e90ff', links: 'https://twitter.com/Junch1225'},
      {icon: 'mdi-youtube', color: '#dc143c', links: 'https://www.youtube.com/c/junch1225'},
      {icon: 'mdi-instagram', color: '#c71585', links: 'https://www.instagram.com/junch1225'},
      {icon: 'mdi-tooltip-text', color: '#228b22', links: 'https://junch1225.hatenablog.com'},
    ],
  })
}
</script>

<style scoped>
#homes {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: black;
  color: white;
}

#menu_home_title {
  display: block;
  color: white;
  margin-top: 20px;
  margin-bottom: 20px;
  text-decoration: underline solid lightskyblue;
}

#menu_title_info {
  display: block;
  color: white;
  margin-top: 40px;
  margin-bottom: 40px;
  text-decoration: underline solid lightskyblue;
}

#info {
  margin-top: 50px;
  margin-bottom: 100px;
}

#url_link {
  color: white;
}

#url_link a {
  color: white;
}

#sns {
  background-color: black;
  margin-bottom: 20px;
}

#sns-btn {
  width: 80px;
  height: 80px;
}

#sns-icon {
  font-size: 46px;
}

@media screen and (max-width: 400px) {

  #homes {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    background-color: black;
    color: white;
  }

  #menu_home_title {
    display: block;
    color: white;
    font-size: 26px;
    margin-top: 10px;
    text-decoration: underline solid lightskyblue;
  }

  #menu_title_info {
    display: block;
    color: white;
    margin-top: 0;
    margin-bottom: 20px;
    text-decoration: underline solid lightskyblue;
  }

  #sns {
    background-color: black;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  #sns-btn {
    width: 50px;
    height: 50px;
  }

  #sns-icon {
    font-size: 28px;
  }

  .movie-wrap {
    position: relative;
    padding-bottom: 56.25%; /*アスペクト比 16:9の場合の縦幅*/
    height: 0;
    overflow: hidden;
  }

  .movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
