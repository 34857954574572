<template>
  <v-app id="app">
    <Navbar_site></Navbar_site>
  <v-main>
    <router-view></router-view>
    <Footer_site></Footer_site>
  </v-main>
  </v-app>
</template>

<script>
import Navbar_site from "@/components/Navbar";
import Footer_site from "@/components/Footer";

export default {
  name: 'App',
  components: {
    Navbar_site,
    Footer_site
  },
}
</script>
<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: black;
  color: white;
}

@media screen and (max-width: 400px){
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    background-color: black;
    color: white;
  }
}
</style>
