<template>
  <v-footer absolute color="primary" dark>
    <v-col class="text-center" cols="12">
      <h4>Copyright© {{ new Date().getFullYear() }} - <strong>JunchanVLOG</strong></h4>
    </v-col>
  </v-footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>

</style>
